import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import requests from "./utils/requests";
// Vue.use(requests)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.prototype.$elconfirm = Vue.prototype.$confirm

import htmlToPdf from './assets/js/htmlToPdf';//路径根据自己的js文件来引入

Vue.use(htmlToPdf);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  requests,
  render: h => h(App)
}).$mount('#app')
