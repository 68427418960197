import axios from "axios";

// const baseUrl = process.env.NODE_ENV === 'production' ? "/" : "/api"

const service = axios.create({
    baseURL:'/api',
    timeout:3 * 1000
})

service.interceptors.request.use(config=>{
        config.data = JSON.stringify(config.data)
        config.headers = {
            'Content-Type':'application/x-www-form-urlencoded' //配置请求头
        }
        config.headers.Authorization = localStorage.getItem('access_token')
        return config
    },error => {
        Promise.reject(error)
    }
)

service.interceptors.response.use(async response=>{
    const res = response.data
    if(res.status===403){
        this.$message.error('长时间未操作，返回登录页!')
        window.location.href = "/"
    }else if(!res.ok){
        this.$message.error(res.message)
    }
    return res
},error => {
    if (error && error.response) {
        // 1.公共错误处理
        // 2.根据响应码具体处理
        switch (error.response.status) {
            case 400:
                error.message = '错误请求'
                break;
            case 401:
                error.message = '未授权，请重新登录'
                break;
            case 403:
                error.message = '拒绝访问'
                break;
            case 404:
                error.message = '请求错误,未找到该资源'
                // window.location.href = "/NotFound"
                break;
            case 405:
                error.message = '请求方法未允许'
                break;
            case 408:
                error.message = '请求超时'
                break;
            case 500:
                error.message = '服务器端出错'
                break;
            case 501:
                error.message = '网络未实现'
                break;
            case 502:
                error.message = '网络错误'
                break;
            case 503:
                error.message = '服务不可用'
                break;
            case 504:
                error.message = '网络超时'
                break;
            case 505:
                error.message = 'http版本不支持该请求'
                break;
            default:
                error.message = `连接错误${error.response.status}`
        }
    } else {
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            this.$message.error('服务器响应超时，请刷新当前页')
        }
        error.message('连接服务器失败')
    }

    Message.error(error.message)
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response)
})
//4.导入文件
export default service
